import { v4 as uuid } from 'uuid';

import { dayjs } from '@totopkg/shared-util-date-time';
import { SecureLocalStorage } from '@totopkg/shared-util-local-storage';

import { IApiLog } from '../type/api-log.type';
import { BaseLog } from './base-log.lib';

const LOCAL_API_LOG_KEY = 'api-log';

const DEFAULT_MAX_ITEMS = 100;

class _LocalApiLog extends BaseLog {
  private _maxItems: number;

  private static _instance: _LocalApiLog;

  private constructor() {
    super();
    this._maxItems = DEFAULT_MAX_ITEMS;
  }

  public static instance = () => {
    if (!this._instance) {
      this._instance = new _LocalApiLog();
    }

    return this._instance;
  };

  config = (config: { maxItems?: number }) => {
    const { maxItems = DEFAULT_MAX_ITEMS } = config;
    this._maxItems = maxItems;
  };

  override write(log: IApiLog): void {
    const _prevLogs = this.read();
    SecureLocalStorage.setItem(
      LOCAL_API_LOG_KEY,
      [
        {
          ...log,
          timeStamp: log.timeStamp || dayjs().toISOString(),
          id: log.id || uuid()
        },
        ..._prevLogs
      ].slice(0, this._maxItems),
      false
    );
  }

  override read(): IApiLog[] {
    return SecureLocalStorage.getItem<IApiLog[]>(LOCAL_API_LOG_KEY, false) || [];
  }

  override clear(): void {
    SecureLocalStorage.remove(LOCAL_API_LOG_KEY);
  }
}

export const LocalApiLog = _LocalApiLog.instance();
