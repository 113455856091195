export enum ESystemLogSeverity {
  alert = 'alert',
  error = 'error',
  warning = 'warning',
  information = 'information',
  debugging = 'debugging'
}

export type TSystemLogSeverity = keyof typeof ESystemLogSeverity;

export interface ISystemLog {
  id?: string;
  host?: string;
  pageUrl?: string;
  url?: string;
  appVersion?: string;
  messageText?: string;
  body?: Record<string, any>;
  severity?: ESystemLogSeverity | TSystemLogSeverity;
  timeStamp?: string;
  userId?: string;
  partnerId?: string;
  deviceId?: string;
  ipAddress?: string;
  country?: string;
}
