import { AxiosHeaderValue, AxiosResponseHeaders, RawAxiosResponseHeaders } from 'axios';

import { ISystemLog } from './system-log.type';

export enum EApiLogMethod {
  GET = 'GET',
  HEAD = 'HEAD',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
  CONNECT = 'CONNECT',
  OPTIONS = 'OPTIONS',
  TRACE = 'TRACE',
  PATCH = 'PATCH'
}

export type TApiLogMethod = keyof typeof EApiLogMethod;

export type TApiRawHeader = {
  [key: string]: AxiosHeaderValue;
};

export interface IApiLog extends ISystemLog {
  requestId?: string;
  method?: EApiLogMethod | TApiLogMethod;
  errorCode?: string;
  errorDescription?: string;

  requestHeader?: TApiRawHeader;
  responseHeader?: RawAxiosResponseHeaders | AxiosResponseHeaders;
}
