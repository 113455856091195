import { ISystemLog } from '../type/system-log.type';

export abstract class BaseLog {
  constructor() {
    this.write = this.write.bind(this);
    this.read = this.read.bind(this);
    this.clear = this.clear.bind(this);
  }

  abstract write(log: ISystemLog): void;

  abstract read(): ISystemLog[];

  abstract clear(): void;
}
